import axios from 'axios'
import Config from '../../config/Config'
const { serverUrl } = Config

const getLoggedInUserInfo = async () => {
    try {
        const response = await axios.get(`${serverUrl}/api/users`)
        return response
    } catch (err) {
        return err.response
    }
}


const updateLoggedInUserInfo = async (data) => {
    try {
        const { name, email, company_name, phone_number } = data
        const response = await axios.put(`${serverUrl}/api/users/loggeduser`, { name, email, company_name, phone_number })
        return response
    } catch (err) {
        return err.response
    }
}

const changePassword = async (formData) => {
    try {
        const response = await axios.post(`${serverUrl}/api/users/changepassword`, { 'oldpassword': formData.oldpassword, 'newpassword': formData.newpassword })
        return response
    } catch (err) {
        return err.response
    }
}

export { getLoggedInUserInfo, updateLoggedInUserInfo, changePassword }