import React from "react";
import { EgretLoadable } from "egret";
import { Redirect } from "react-router-dom";

const SupportedURL = EgretLoadable({
  loader: () => import("./SupportedURL"),
});

const supportedURL = [
  {
    path: "/supported/manage",
    exact: true,
    render: (props) =>
      localStorage.getItem("token") ? (
        <SupportedURL />
      ) : (
        <Redirect to="/session/signin" />
      ),
  },
];

export default supportedURL;
