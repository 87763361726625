import axios from 'axios'
import Config from '../../config/Config'
const { serverUrl } = Config

const userLogin = async (formData) => {
    try {
        const response = await axios.post(`${serverUrl}/api/users/login`, { 'email': formData.email, 'password': formData.password })
        return response
    } catch (err) {
        return err.response
    }

}

const changePassword = async (formData) => {
    try {
        const response = await axios.post(`${serverUrl}/api/users/changepassword`, { 'oldpassword': formData.oldpassword, 'newpassword': formData.newpassword })
        return response
    } catch (err) {
        return err.response
    }
}

const forgotPassword = async (formData) => {
    try {
        const response = await axios.post(`${serverUrl}/api/users/forgotpassword`, { 'email': formData.email })
        return response
    } catch (err) {
        return err.response
    }
}

const resetPassword = async (formData) => {
    try {
        const response = await axios.post(`${serverUrl}/api/users/resetpassword`, { 'token': formData.token, 'newpassword': formData.newpassword })
        return response
    } catch (err) {
        return err.response
    }
}

export { userLogin, changePassword, forgotPassword, resetPassword }