import React, { useState, useContext } from "react";
import {
  Card,
  Checkbox,
  FormControlLabel,
  Grid,
  Button,
  withStyles,
} from "@material-ui/core";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import history from "history.js";
import { userLogin } from './user.service'
import { toast } from 'react-toastify';
import setAuthToken from "app/utils/setAuthToken";
import AppContext from "app/appContext";


const styles = theme => ({
  wrapper: {
    position: "relative"
  },

  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
});

const SignIn = (props) => {

  const { setUser } = useContext(AppContext);

  let { classes } = props;

  const [formData, setFormData] = useState({
    email: '',
    password: ''
  })

  const { email, password } = formData

  const onFormSubmit = async (e) => {
    e.preventDefault()
    const response = await userLogin(formData)
    if (response.status === 200) {
      const { data: { data: { jwt_token, userDetails } } } = response

      if (userDetails.is_admin) {
        setUser(userDetails)
        localStorage.setItem('token', jwt_token)
        setAuthToken(localStorage.getItem('token'))
        setFormData({ ...formData, email: '', password: '' })
        props.history.push({
          pathname: "/user-management"
        });
      }

      else {
        console.log("I am executing, else block")
        toast.error('You are not Admin!')
      }

    } else {
      const message = response.data.message
      toast.error(message)
    }

  }

  const onFieldChange = (e) => {
    const { name, value } = e.target
    setFormData({ ...formData, [name]: value })
  }

  return (
    <div className="signup flex flex-center w-100 h-100vh">
      <div className="p-8">
        <Card className="signup-card position-relative y-center">
          <Grid container>

            <Grid item lg={5} md={5} sm={5} xs={12}>
              <div className="flex flex-center flex-middle h-100">
                <img src="/assets/images/illustrations/neon.png" alt="" />
              </div>
            </Grid>

            <Grid item lg={7} md={7} sm={7} xs={12}>
              <div className="p-36 h-100 bg-light-gray position-relative">
                <ValidatorForm onSubmit={onFormSubmit}>
                  <TextValidator
                    className="mb-24 w-100"
                    variant="outlined"
                    label="Email"
                    type="email"
                    name="email"
                    value={email}
                    validators={["required", "isEmail"]}
                    errorMessages={[
                      "this field is required",
                      "email is not valid"
                    ]}
                    onChange={onFieldChange}
                  />
                  <TextValidator
                    className="mb-16 w-100"
                    label="Password"
                    variant="outlined"
                    name="password"
                    type="password"
                    value={password}
                    validators={["required"]}
                    errorMessages={["this field is required"]}
                    onChange={onFieldChange}
                  />
                  <div className="flex flex-middle mb-8">
                    <div className={classes.wrapper}>
                      <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                      >
                        Sign in
                      </Button>
                    </div>
                  </div>
                  {/* <Button
                    className="text-primary"
                    onClick={() =>
                      props.history.push("/session/forgot-password")
                    }
                  >
                    Forgot password?
                  </Button> */}
                </ValidatorForm>
              </div>
            </Grid>

          </Grid>
        </Card>
      </div>

    </div >
  );
}

export default withStyles(styles, { withTheme: true })(SignIn)
