import React from 'react'
import { EgretLoadable } from "egret";
import { Redirect } from "react-router-dom";

const ScraperManage = EgretLoadable({
    loader: () => import("./ScraperManage")
});



const scraperRoutes = [
    {
        path: "/scraper/manage",
        exact: true,
        render: (props) => localStorage.getItem('token') ? <ScraperManage /> : <Redirect to="/session/signin" />,
    },

];

export default scraperRoutes;
