import "../fake-db";
import "../styles/_app.scss";
import React, { useEffect, useState } from "react";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import EgretTheme from "./EgretLayout/EgretTheme/EgretTheme";
import AppContext from "./appContext";
import history from "history.js";

import routes from "./RootRoutes";
import { Store } from "./redux/Store";
import EgretLayout from "./EgretLayout/EgretLayout";
import setAuthToken from "./utils/setAuthToken";
import { toast } from "react-toastify";
import axios from 'axios'
import Config from './config/Config'
const { serverUrl } = Config

const App = () => {
  const [loggedInUser, setUser] = useState({})

  const fetchUserDetails = async () => {
    try {
      const response = await axios.get(`${serverUrl}/api/users`)
      if (response.status === 200) {
        const { data: { user } } = response
        setUser(user)
      }
    } catch (err) {
      toast.error('Not able to get User Details')
    }
  }

  useEffect(() => {
    if (localStorage.token) {
      fetchUserDetails()
    }
  }, [])

  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  return (
    <AppContext.Provider value={{ routes, loggedInUser, setUser }}>
      <Provider store={Store}>
        <EgretTheme>
          <Router history={history}>
            <EgretLayout />
          </Router>
        </EgretTheme>
      </Provider>
    </AppContext.Provider>
  );
};

export default App;
