import React from 'react'
import { EgretLoadable } from "egret";
import { Redirect } from "react-router-dom";
import ChangePassword from './ChangePassword';

const Profile = EgretLoadable({
    loader: () => import('./Profile')
})

const adminRoutes = [
    {
        path: '/profile',
        exact: true,
        render: (props) => localStorage.getItem('token') ? <Profile /> : <Redirect to="/session/signin" />,
    },
    {
        path: '/session/change-password',
        exact: true,
        render: (props) => localStorage.getItem('token') ? <ChangePassword /> : <Redirect to="/session/signin" />
    }
]


export default adminRoutes;