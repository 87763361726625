import React from 'react'
import { EgretLoadable } from "egret";
import { Redirect } from "react-router-dom";

const UserMangement = EgretLoadable({
  loader: () => import("./UserManagement")
});

const SignUp = EgretLoadable({
  loader: () => import('./SignUp')
})

const userRoute = [
  {
    path: "/user-management",
    exact: true,
    render: (props) => localStorage.getItem('token') ? <UserMangement /> : <Redirect to="/session/signin" />,
  },
  {
    path: "/user-management/add-new-user",
    exact: true,
    render: (props) => localStorage.getItem('token') ? <SignUp /> : <Redirect to="/session/signin" />,
  }
];

export default userRoute;
